import React, { useState } from "react";
import { LinkIcon } from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";
import { SocialIcon } from "react-social-icons";

export default function CompanyProfile({
  company = "Company Name",
  tagline = "Tagline goes here...",
  biography = "Bio goes here...",
  website = "Website.com",
  address = "Address goes here...",
  wallpaperImage = "/placeholder.svg?height=300&width=1000",
  logoImage = "/placeholder.svg?height=50&width=50",
  contactInfo = [],
  links = { social: [], other: [] },
  accentColor = "#3498db",
  backgroundColor = "#f0f0f0",
  primaryColor = "#333333",
  secondaryColor = "#666666",
  onEvent = (event) => {},
}) {
  const [isBioExpanded, setIsBioExpanded] = useState(true);
  const [isContactsExpanded, setIsContactsExpanded] = useState(true);
  const [isLinksExpanded, setIsLinksExpanded] = useState(true);
  const toggleBio = () => {
    onEvent({ action_type: "bio_read", target: "company_bio" });
    setIsBioExpanded(!isBioExpanded);
  };

  const openWebsite = (site) => {
    onEvent({ action_type: "visit_website", target: site });
    if (site.includes("http") || site.includes("https")) {
      window.open(site, "_blank");
    } else {
      window.open("http://" + site, "_blank");
    }
  };

  return (
    <div
      className="max-w-2xl mx-auto p-0 rounded-lg mt-1 shadow-2xl"
      style={{
        "--accent-color": accentColor,
        "--background-color": backgroundColor,
        "--primary-color": primaryColor,
        "--secondary-color": secondaryColor,
        backgroundColor: "var(--background-color)",
      }}
    >
      {/* <div className="relative mb-12">
        <div className="relative">
          <img
            src={wallpaperImage}
            alt="Wallpaper"
            width={1000}
            height={216}
            className="w-full h-32 object-contain rounded-t-lg bg-slate-50 border-b-slate-300"
          />
          <div className="absolute inset-0 bg-black opacity-30 rounded-t-lg"></div>
        </div>
        <img
          src={logoImage ? logoImage : "/placeholder.jpg"}
          alt={company}
          width={144}
          height={144}
          className="absolute left-24 transform -translate-x-1/2 -bottom-12 w-28 h-28 rounded-full border-4 bg-slate-50 object-contain"
          style={{ borderColor: "var(--accent-color)" }}
        />
      </div> */}
      <div className="p-4">
        {/* <div className="flex flex-col items-start gap-2 p-6">
          <div className="flex items-center justify-center">
            <div>
              <h2
                className="text-xl font-semibold"
                style={{ color: "var(--primary-color)" }}
              >
                {company}
              </h2>
              <p className="italic" style={{ color: "var(--secondary-color)" }}>
                {tagline}
              </p>
            </div>
          </div>
          {!isBioExpanded && (
            <button
              className="ml-4 flex gap-2 items-center"
              onClick={toggleBio}
              style={{ color: "var(--accent-color)" }}
            >
              <InformationCircleIcon
                className=" w-8"
                style={{ color: "var(--accent-color)" }}
              />{" "}
              See more
            </button>
          )}
        </div> */}
        <AnimatePresence initial={false}>
          {isBioExpanded && (
            <motion.div
              key="content"
              initial="collapsed"
              animate="expanded"
              exit="collapsed"
              variants={{
                expanded: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              style={{ transformOrigin: "top" }}
              className="p-"
            >
              <motion.div
                variants={{
                  expanded: { rotateX: 0 },
                  collapsed: { rotateX: -90 },
                }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                className="flex flex-col gap-6"
              >
                {" "}
                {/* <div className="">
                  <div className="flex gap-2 ">
                    <p style={{ color: "var(--secondary-color)" }}>
                      {biography}
                    </p>
                  </div>
                </div> */}
                {/* <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                  <div className="flex items-center mb-2 sm:mb-0">
                    <GlobeAltIcon
                      className="mr-2 w-6"
                      style={{ color: "var(--accent-color)" }}
                    />
                    <a
                      href={`${website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                      style={{ color: "var(--accent-color)" }}
                      onClick={() =>
                        onEvent({
                          action_type: "visit_website",
                          target: "company_website",
                        })
                      }
                    >
                      {website}
                    </a>
                  </div>
                  <div className="flex items-center">
                    <MapIcon
                      className="mr-2 w-6"
                      style={{ color: "var(--accent-color)" }}
                    />
                    <span style={{ color: "var(--secondary-color)" }}>
                      {address}
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex items-end justify-end">
                  <button
                    variant="link"
                    onClick={toggleBio}
                    className="font-normal text-sm mt-4"
                    style={{ color: "var(--accent-color)" }}
                  >
                    See less
                  </button>
                </div> */}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* <div className="pl-6 pr-6">
          <h2
            className="text-xl font-semibold mb-4"
            style={{ color: "var(--primary-color)" }}
          >
            Contact information
          </h2>
          {contactInfo.map((info) => (
            <div className="flex flex-col gap-1 pb-4" key={info.title}>
              <div
                className="text-md font-semibold"
                style={{ color: "var(--secondary-color)" }}
              >
                {info.title}
              </div>
              <div
                className="text-md"
                style={{ color: "var(--secondary-color)" }}
              >
                {info.value}
              </div>
            </div>
          ))}
        </div> */}
        <div className="pr-2 pl-2">
          <div
            className="text-lg font-semibold mb-2"
            style={{ color: "var(--primary-color)" }}
          >
            Links
          </div>
          <div className="flex flex-col items-start justify-start pt-2 flex-wrap">
            {links &&
              links.other &&
              links.other.map((link) => (
                <button
                  key={link.url}
                  className="flex gap-2 py-2 text-md items-center"
                  style={{ color: "var(--secondary-color)" }}
                  onClick={() => openWebsite(link.url)}
                >
                  <LinkIcon className="w-4 h-4" /> {link.title}
                </button>
              ))}
          </div>
          <div className="w-full border-t mt-4 mb-4" />
          <div className="flex flex-row gap-2 items-start justify-start flex-wrap">
            {links &&
              links.social &&
              links.social.map((social) => (
                <button
                  key={social}
                  className="flex  gap-2 py-2"
                  onClick={() => openWebsite(social)}
                >
                  <SocialIcon url={social} />
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
